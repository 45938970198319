import React, { useState, useEffect } from 'react';

function AddCarForm() {
  const [car, setCar] = useState({
    model: '',
    range: '',
    battery_capacity: '',
    battery_type: '',
    one_pedal_driving: '',
    power: '',
    phone_mirroring: '',
    head_up_display: '',
    has_actual_stalks: '',
    memory_seats: '',
    ac_charging: '',
    dc_charging_min: '',
    dc_charging_max: '',
    battery_voltage: ''
  });
  const [existingCars, setExistingCars] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/cars')
      .then(response => response.json())
      .then(data => setExistingCars(data))
      .catch(error => console.error('Error fetching existing cars:', error));
  }, []);

  const handleChange = (e) => {
    setCar({ ...car, [e.target.name]: e.target.value });
  };

  const generateSqlContent = (newCar) => {
    const allCars = [...existingCars, newCar];
    return allCars.map(car => 
      `('${car.model}', ${car.range}, ${car.battery_capacity}, '${car.battery_type}', '${car.one_pedal_driving}', ${car.power}, '${car.phone_mirroring}', '${car.head_up_display}', '${car.has_actual_stalks}', '${car.memory_seats}', '${car.ac_charging}', ${car.dc_charging_min}, ${car.dc_charging_max}, ${car.battery_voltage})`
    ).join(',\n') + ';';
  };

  const downloadSqlFile = (content) => {
    const element = document.createElement('a');
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = 'updated_cars.sql';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    try {
      const sqlContent = generateSqlContent(car);
      downloadSqlFile(sqlContent);
      setCar({
        model: '',
        range: '',
        battery_capacity: '',
        battery_type: '',
        one_pedal_driving: '',
        power: '',
        phone_mirroring: '',
        head_up_display: '',
        has_actual_stalks: '',
        memory_seats: '',
        ac_charging: '',
        dc_charging_min: '',
        dc_charging_max: '',
        battery_voltage: ''
      });
      alert('SQL file generated and downloaded successfully!');
    } catch (error) {
      console.error('Error generating SQL file:', error);
      setError(error.message || 'Error generating SQL file. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="add-car-form">
      <h2>Add New Electric Car</h2>
      {error && <div className="error-message">{error}</div>}
      <input
        type="text"
        name="model"
        value={car.model}
        onChange={handleChange}
        placeholder="Model"
        required
      />
      <input
        type="number"
        name="range"
        value={car.range}
        onChange={handleChange}
        placeholder="Range (km)"
        required
      />
      <input
        type="number"
        name="battery_capacity"
        value={car.battery_capacity}
        onChange={handleChange}
        placeholder="Battery Capacity (kWh)"
        step="0.01"
        required
      />
      <input
        type="text"
        name="battery_type"
        value={car.battery_type}
        onChange={handleChange}
        placeholder="Battery Type"
        required
      />
      <select
        name="one_pedal_driving"
        value={car.one_pedal_driving}
        onChange={handleChange}
        required
      >
        <option value="">One Pedal Driving</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <input
        type="number"
        name="power"
        value={car.power}
        onChange={handleChange}
        placeholder="Power (kW)"
        required
      />
      <select
        name="phone_mirroring"
        value={car.phone_mirroring}
        onChange={handleChange}
        required
      >
        <option value="">Phone Mirroring</option>
        <option value="Wireless">Wireless</option>
        <option value="Wired">Wired</option>
        <option value="None">None</option>
      </select>
      <select
        name="head_up_display"
        value={car.head_up_display}
        onChange={handleChange}
        required
      >
        <option value="">Head Up Display</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <select
        name="has_actual_stalks"
        value={car.has_actual_stalks}
        onChange={handleChange}
        required
      >
        <option value="">Has Actual Stalks</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <select
        name="memory_seats"
        value={car.memory_seats}
        onChange={handleChange}
        required
      >
        <option value="">Memory Seats</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <select
        name="ac_charging"
        value={car.ac_charging}
        onChange={handleChange}
        required
      >
        <option value="">AC Charging</option>
        <option value="Below 7kW">Below 7kW</option>
        <option value="up to 7kW">up to 7kW</option>
        <option value="up to 11kW">up to 11kW</option>
        <option value="22kW and more">22kW and more</option>
      </select>
      <input
        type="number"
        name="dc_charging_min"
        value={car.dc_charging_min}
        onChange={handleChange}
        placeholder="DC Charging Min (kW)"
        required
      />
      <input
        type="number"
        name="dc_charging_max"
        value={car.dc_charging_max}
        onChange={handleChange}
        placeholder="DC Charging Max (kW)"
        required
      />
      <input
        type="number"
        name="battery_voltage"
        value={car.battery_voltage}
        onChange={handleChange}
        placeholder="Battery Voltage"
        required
      />
      <button type="submit">Generate SQL and Download</button>
    </form>
  );
}

export default AddCarForm;