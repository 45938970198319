import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ElectricCarList from './ElectricCarList';
import AddCarForm from './AddCarForm';
import './App.css';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Electric Car Database</h1>
          <button onClick={toggleDarkMode} className="dark-mode-toggle">
            {isDarkMode ? '☀️' : '🌙'}
          </button>
        </header>
        <main>
          <Switch>
            <Route exact path="/" component={ElectricCarList} />
            <Route path="/add" component={AddCarForm} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;