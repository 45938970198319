import React, { useState, useEffect } from 'react';
import './ElectricCarList.css';

function ElectricCarList() {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [filters, setFilters] = useState({
    minRange: '',
    maxRange: '',
    minBatteryCapacity: '',
    maxBatteryCapacity: '',
    onePedalDriving: '',
    phoneMirroring: '',
    headUpDisplay: '',
    hasActualStalks: '',
    memorySeats: '',
    acCharging: '',
    minDcCharging: '',
    maxDcCharging: '',
    minBatteryVoltage: '',
    maxBatteryVoltage: '',
  });
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [visibleColumns, setVisibleColumns] = useState({
    range: true,
    battery_capacity: true,
    one_pedal_driving: true,
    power: true,
    ac_charging: true,
    dc_charging: true,
    phone_mirroring: false,
    head_up_display: false,
    has_actual_stalks: false,
    memory_seats: false,
    battery_voltage: false,
  });
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  useEffect(() => {
    fetchCars();
  }, []);

  useEffect(() => {
    setIsApplyEnabled(Object.values(filters).some(value => value !== ''));
  }, [filters]);

  const fetchCars = async () => {
    try {
      const response = await fetch('/api/cars');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCars(data);
      setFilteredCars(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const applyFilters = () => {
    const filtered = cars.filter(car => {
      return (
        (!filters.minRange || car.range >= parseInt(filters.minRange)) &&
        (!filters.maxRange || car.range <= parseInt(filters.maxRange)) &&
        (!filters.minBatteryCapacity || car.battery_capacity >= parseFloat(filters.minBatteryCapacity)) &&
        (!filters.maxBatteryCapacity || car.battery_capacity <= parseFloat(filters.maxBatteryCapacity)) &&
        (!filters.onePedalDriving || car.one_pedal_driving === filters.onePedalDriving) &&
        (!filters.phoneMirroring || car.phone_mirroring === filters.phoneMirroring) &&
        (!filters.headUpDisplay || car.head_up_display === filters.headUpDisplay) &&
        (!filters.hasActualStalks || car.has_actual_stalks === filters.hasActualStalks) &&
        (!filters.memorySeats || car.memory_seats === filters.memorySeats) &&
        (!filters.acCharging || car.ac_charging === filters.acCharging) &&
        (!filters.minDcCharging || car.dc_charging >= parseInt(filters.minDcCharging)) &&
        (!filters.maxDcCharging || car.dc_charging <= parseInt(filters.maxDcCharging)) &&
        (!filters.minBatteryVoltage || car.battery_voltage >= parseInt(filters.minBatteryVoltage)) &&
        (!filters.maxBatteryVoltage || car.battery_voltage <= parseInt(filters.maxBatteryVoltage))
      );
    });
    setFilteredCars(filtered);
  };

  const resetFilters = () => {
    setFilters({
      minRange: '',
      maxRange: '',
      minBatteryCapacity: '',
      maxBatteryCapacity: '',
      onePedalDriving: '',
      phoneMirroring: '',
      headUpDisplay: '',
      hasActualStalks: '',
      memorySeats: '',
      acCharging: '',
      minDcCharging: '',
      maxDcCharging: '',
      minBatteryVoltage: '',
      maxBatteryVoltage: '',
    });
    fetchCars();
  };

  const sortCars = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    const sortedCars = [...filteredCars].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setFilteredCars(sortedCars);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns(prev => ({ ...prev, [column]: !prev[column] }));
  };

  const toggleMoreFilters = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  return (
    <div className="electric-car-list">
      <div className="filters">
        {/* Standard filters */}
        <div className="filter-category">
          <label>
            <input
              type="checkbox"
              checked={visibleColumns.range}
              onChange={() => toggleColumnVisibility('range')}
            />
            Range (km):
          </label>
          <div className="filter-inputs">
            <input
              type="number"
              name="minRange"
              placeholder="Min"
              value={filters.minRange}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxRange"
              placeholder="Max"
              value={filters.maxRange}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="filter-category">
          <label>
            <input
              type="checkbox"
              checked={visibleColumns.battery_capacity}
              onChange={() => toggleColumnVisibility('battery_capacity')}
            />
            Battery Capacity (kWh):
          </label>
          <div className="filter-inputs">
            <input
              type="number"
              name="minBatteryCapacity"
              placeholder="Min"
              value={filters.minBatteryCapacity}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxBatteryCapacity"
              placeholder="Max"
              value={filters.maxBatteryCapacity}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="filter-category">
          <label>
            <input
              type="checkbox"
              checked={visibleColumns.one_pedal_driving}
              onChange={() => toggleColumnVisibility('one_pedal_driving')}
            />
            One Pedal Driving:
          </label>
          <div className="filter-inputs">
            <select
              name="onePedalDriving"
              value={filters.onePedalDriving}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div className="filter-category">
          <label>
            <input
              type="checkbox"
              checked={visibleColumns.power}
              onChange={() => toggleColumnVisibility('power')}
            />
            Power (kW):
          </label>
          <div className="filter-inputs">
            <input
              type="number"
              name="minPower"
              placeholder="Min"
              value={filters.minPower}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxPower"
              placeholder="Max"
              value={filters.maxPower}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="filter-category">
          <label>
            <input
              type="checkbox"
              checked={visibleColumns.ac_charging}
              onChange={() => toggleColumnVisibility('ac_charging')}
            />
            AC Charging:
          </label>
          <div className="filter-inputs">
            <select
              name="acCharging"
              value={filters.acCharging}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              <option value="Below 7kW">Below 7kW</option>
              <option value="up to 7kW">up to 7kW</option>
              <option value="up to 11kW">up to 11kW</option>
              <option value="22kW and more">22kW and more</option>
            </select>
          </div>
        </div>
        <div className="filter-category">
          <label>
            <input
              type="checkbox"
              checked={visibleColumns.dc_charging}
              onChange={() => toggleColumnVisibility('dc_charging')}
            />
            DC Charging (kW):
          </label>
          <div className="filter-inputs">
            <input
              type="number"
              name="minDcCharging"
              placeholder="Min"
              value={filters.minDcCharging}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxDcCharging"
              placeholder="Max"
              value={filters.maxDcCharging}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        
        {showMoreFilters && (
          <>
            {/* Additional filters */}
            <div className="filter-category">
              <label>
                <input
                  type="checkbox"
                  checked={visibleColumns.phone_mirroring}
                  onChange={() => toggleColumnVisibility('phone_mirroring')}
                />
                Phone Mirroring:
              </label>
              <div className="filter-inputs">
                <select
                  name="phoneMirroring"
                  value={filters.phoneMirroring}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Wireless">Wireless</option>
                  <option value="Wired">Wired</option>
                  <option value="None">None</option>
                </select>
              </div>
            </div>
            <div className="filter-category">
              <label>
                <input
                  type="checkbox"
                  checked={visibleColumns.head_up_display}
                  onChange={() => toggleColumnVisibility('head_up_display')}
                />
                Head Up Display:
              </label>
              <div className="filter-inputs">
                <select
                  name="headUpDisplay"
                  value={filters.headUpDisplay}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="filter-category">
              <label>
                <input
                  type="checkbox"
                  checked={visibleColumns.has_actual_stalks}
                  onChange={() => toggleColumnVisibility('has_actual_stalks')}
                />
                Has Actual Stalks:
              </label>
              <div className="filter-inputs">
                <select
                  name="hasActualStalks"
                  value={filters.hasActualStalks}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="filter-category">
              <label>
                <input
                  type="checkbox"
                  checked={visibleColumns.memory_seats}
                  onChange={() => toggleColumnVisibility('memory_seats')}
                />
                Memory Seats:
              </label>
              <div className="filter-inputs">
                <select
                  name="memorySeats"
                  value={filters.memorySeats}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="filter-category">
              <label>
                <input
                  type="checkbox"
                  checked={visibleColumns.battery_voltage}
                  onChange={() => toggleColumnVisibility('battery_voltage')}
                />
                Battery Voltage:
              </label>
              <div className="filter-inputs">
                <input
                  type="number"
                  name="minBatteryVoltage"
                  placeholder="Min"
                  value={filters.minBatteryVoltage}
                  onChange={handleFilterChange}
                />
                <input
                  type="number"
                  name="maxBatteryVoltage"
                  placeholder="Max"
                  value={filters.maxBatteryVoltage}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
          </>
        )}
        <button onClick={toggleMoreFilters} className="more-filters-button">
          {showMoreFilters ? 'Less Filters' : 'More Filters'}
        </button>
        <div className="button-group">
          <button onClick={applyFilters} disabled={!isApplyEnabled} className="apply-button">
            Apply Filters
          </button>
          <button onClick={resetFilters} className="reset-button">
            Reset Filters
          </button>
        </div>
      </div>
      <p className="car-count">Showing {filteredCars.length} of {cars.length} cars</p>
      <table className="car-table">
        <thead>
          <tr>
            <th onClick={() => sortCars('model')}>Model{getSortIndicator('model')}</th>
            {visibleColumns.range && <th onClick={() => sortCars('range')}>Range (km){getSortIndicator('range')}</th>}
            {visibleColumns.battery_capacity && <th onClick={() => sortCars('battery_capacity')}>Battery (kWh){getSortIndicator('battery_capacity')}</th>}
            {visibleColumns.one_pedal_driving && <th onClick={() => sortCars('one_pedal_driving')}>One Pedal Driving{getSortIndicator('one_pedal_driving')}</th>}
            {visibleColumns.power && <th onClick={() => sortCars('power')}>Power (kW){getSortIndicator('power')}</th>}
            {visibleColumns.phone_mirroring && <th onClick={() => sortCars('phone_mirroring')}>Phone Mirroring{getSortIndicator('phone_mirroring')}</th>}
            {visibleColumns.head_up_display && <th onClick={() => sortCars('head_up_display')}>Head Up Display{getSortIndicator('head_up_display')}</th>}
            {visibleColumns.has_actual_stalks && <th onClick={() => sortCars('has_actual_stalks')}>Has Actual Stalks{getSortIndicator('has_actual_stalks')}</th>}
            {visibleColumns.memory_seats && <th onClick={() => sortCars('memory_seats')}>Memory Seats{getSortIndicator('memory_seats')}</th>}
            {visibleColumns.ac_charging && <th onClick={() => sortCars('ac_charging')}>AC Charging{getSortIndicator('ac_charging')}</th>}
            {visibleColumns.dc_charging && <th onClick={() => sortCars('dc_charging')}>DC Charging (kW){getSortIndicator('dc_charging')}</th>}
            {visibleColumns.battery_voltage && <th onClick={() => sortCars('battery_voltage')}>Battery Voltage{getSortIndicator('battery_voltage')}</th>}
          </tr>
        </thead>
        <tbody>
          {filteredCars.map(car => (
            <tr key={car.id}>
              <td>{car.model}</td>
              {visibleColumns.range && <td>{car.range}</td>}
              {visibleColumns.battery_capacity && <td>{car.battery_capacity}</td>}
              {visibleColumns.one_pedal_driving && <td>{car.one_pedal_driving}</td>}
              {visibleColumns.power && <td>{car.power}</td>}
              {visibleColumns.phone_mirroring && <td>{car.phone_mirroring}</td>}
              {visibleColumns.head_up_display && <td>{car.head_up_display}</td>}
              {visibleColumns.has_actual_stalks && <td>{car.has_actual_stalks}</td>}
              {visibleColumns.memory_seats && <td>{car.memory_seats}</td>}
              {visibleColumns.ac_charging && <td>{car.ac_charging}</td>}
              {visibleColumns.dc_charging && <td>{car.dc_charging}</td>}
              {visibleColumns.battery_voltage && <td>{car.battery_voltage}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ElectricCarList;